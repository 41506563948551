/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import {
  ref,
  getCurrentInstance,
  reactive,
  computed,
  onMounted
} from 'vue'
import {
  environment
} from '@/utils'
import {
  newPackageUseCase
} from '@/domain/usecase'

export default {
  name: 'ModalChangeCourier',
  props: {
    isShowDialog: {
      default: () => false
    },
    dataPackage: {
      default: () => []
    },
    from: {
      default: () => null
    }
  },
  emits: ['hideDialog', 'submitTransferCourier', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $toast,
    } = app!.appContext.config.globalProperties
    const hideDialog = () => {
      emit('hideDialog')
    }
    const API_BASE = environment.getApiBase()
    const propertiesProps = reactive(props)
    const reason = ref(null)
    // const isShowDialog = reactive(props.isShowDialog)
    // const dataPackage = reactive(props.dataPackage)
    const listPackage = ref(propertiesProps.dataPackage)
    const fromPath = ref(propertiesProps.from)
    const isShowModal = computed({
      get: () => propertiesProps.isShowDialog,
      set: (val) => {
        if (!val) {
          hideDialog()
        }
      }
    })

    const dataTransferCourier = ref([])
    const isTransferCourier = () => {
      dataTransferCourier.value = listPackage.value.filter((x: any) => x.IsActive)
      if (dataTransferCourier.value.length > 0) {
        return true
      }
      return false
    }

    const dataChangeCourier = ref([])
    const isChangeCourier = () => {
      dataChangeCourier.value = listPackage.value.filter((x: any) => !x.IsActive)
      if (dataChangeCourier.value.length > 0) {
        return true
      } return false
    }

    const processMappingPackage = (list: any) => {
      const mapArr = list.map((x: any) => ({
        IdPaket: x.IdPaket ?? x.KurirTransUserPengirimanId
      }))
      return mapArr
    }

    const responseResult = (res: any) => {
      console.log('res', res)
      if (!res.error) {
        $toast.add({
          severity: 'success',
          detail: res.result.detail ?? res.result.Detail,
          group: 'bc',
          life: 2000
        })
        hideDialog()
        emit('reloadData')
      } else {
        $toast.add({
          severity: 'error',
          detail: res.message,
          group: 'bc',
          life: 2000
        })
      }
    }

    const processTransferOrChange = async () => {
      const dataSend = {
        Keterangan: reason.value,
        KurirMsUserId: listPackage.value[0].KurirPengganti.Id,
      } as any
      let response: any
      if (isTransferCourier()) {
        const listPackageTransferCourier = processMappingPackage(dataTransferCourier.value)
        dataSend.Paket = listPackageTransferCourier
        response = await newPackageUseCase.changeTransferCourier(dataSend)
        responseResult(response)
      }
      if (isChangeCourier()) {
        const listPackageChangeCourier = processMappingPackage(dataChangeCourier.value)
        console.log('listPackageChangeCourier', listPackageChangeCourier)
        dataSend.Paket = listPackageChangeCourier
        response = await newPackageUseCase.submitChangeCourier(dataSend)
        responseResult(response)
      }
    }

    const processSubmit = () => {
      if (fromPath.value === 'transfer-kurir') {
        processTransferOrChange()
      } else if (fromPath.value === 'ganti-kurir') {
        processTransferOrChange()
      }
    }
    const submit = () => {
      if (reason.value) {
        processSubmit()
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Pastikan alasan sudah diisi',
          group: 'bc',
          life: 1500
        })
      }
    }

    const labelReason = () => {
      if (fromPath.value === 'transfer-kurir') {
        return 'Alasan Pengembalian'
      } else if (fromPath.value === 'ganti-kurir') {
        return 'Alasan Penggantian Kurir'
      }
    }

    onMounted(() => {
      console.log('listPackage', listPackage.value)
    })

    return {
      isShowModal,
      hideDialog,
      listPackage,
      API_BASE,
      reason,
      submit,
      fromPath,
      labelReason
    }
  }
}
