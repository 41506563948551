<template>
  <ul class="container-tabs-filter">
    <li class="container-button gap-2" :class="positionButton == data.key ? 'select-button' : 'select-button-null'" v-for="data in dataFilter" :key="data.key" v-on:click="changePosition(data)">
      <div class="flex gap-2 mx-3 mt-1 mb-2">
        <img :src="data.img" />
        <p class="font-semibold text-sm" style="white-space: nowrap;">
          {{ data.label }}
        </p>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'TabsFilter',
  props: ['dataFilter', 'positionButton'],
  data() {
    return {}
  },
  methods: {
    // handleScroll(event) {
    //     Any code to be executed when the window is scrolled
    //     console.log('event scroll', event);
    // },
    scroll_left() {
      const content = document.querySelector('.container-tabs-filter')
      content.scrollLeft -= 50
    },
    scroll_right() {
      const content = document.querySelector('.container-tabs-filter')
      content.scrollLeft += 50
    },
    changePosition(val) {
      // console.log("change button ", val)
      // this.positionButton = val.key
      this.$emit('filterChange', val)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/scss/shared-components/tabs-filter";
</style>
