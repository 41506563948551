/* eslint-disable no-unused-expressions, prefer-destructuring, no-await-in-loop, no-self-assign */
import {
  ref,
  watch,
  onMounted,
  getCurrentInstance,
  onUnmounted,
  defineComponent
} from 'vue'
import TabsFilter from '@/views/shared-components/tabs/TabsFilter.vue'
import CardReadyPickUpNew from '@/views/package-order/new-package/ready-pick-up/CardReadyPickUpNew.vue'
import ReadyDeliver from '@/views/monitoring-package/ready-deliver/index.vue'
import {
  newPackageUseCase,
  getAllItemsKategoriBarang,
  receiveItemFileUseCase
} from '@/domain/usecase'
import { useStore } from 'vuex'
import FormAssignCourier from '@/views/package-order/new-package/FormAssignCourier.vue'
import ModalChangeCourier from '@/views/package-order/new-package/form/ModalChangeCourier.vue'
import ModalAcceptCancelPackage from '@/views/package-order/new-package/form/ModalAcceptCancelPackage.vue'
import moment from 'moment'
import {
  isNull,
  find,
  chain,
  orderBy
} from 'lodash'
import type {
  TDataConfirmPackageProp
} from '@/views/monitoring-package/ready-deliver/types/TPackageDelivery'
import {
  ModalConfirmPackageReturn
} from '@/views/monitoring-package/ready-deliver/components/modal'
import { compressImage } from '@/plugins/compressor'

export default defineComponent({
  name: 'NewPackage',
  components: {
    TabsFilter,
    CardReadyPickUpNew,
    FormAssignCourier,
    ModalChangeCourier,
    ModalAcceptCancelPackage,
    ReadyDeliver,
    ModalConfirmPackageReturn
  },
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const dataFilter = ref([
      {
        label: 'Siap Dijemput',
        img: require('@/assets/img/icon/right_circle_red.svg'),
        key: 0
      },
      {
        label: 'Siap Diantar',
        img: require('@/assets/img/icon/down_circle_green.svg'),
        key: 1
      }
    ])
    const submitSearchKey = ref('')
    const inputSearchReceipt = ref(null)
    const positionButton = ref(dataFilter.value[0].key)
    const sliderActive = ref(false)
    const listCourierSchedule = ref<any>([])
    const listSchedule = ref<any>([])
    const listScheduleNew = ref<any>([])
    const isAllListCourierSchedule = ref(true)
    const refFilter = ref()
    const refreshDate = ref('')
    const refRefreshTime = ref()
    const filterCategoryList = ref([]) as any
    const refFilterCategory = ref()
    const selectedRefreshTime = ref(5)
    const selectedFilterCategory = ref(-1)
    const selectedFilterCategoryName = ref('SEMUA')
    const requestedCancelPackage = ref(null)
    const refreshDataoption = ref([
      {
        name: '5 Menit',
        value: 5
      },
      {
        name: '10 Menit',
        value: 10
      },
      {
        name: '15 Menit',
        value: 15
      },
      {
        name: '20 Menit',
        value: 20
      },
      {
        name: '25 Menit',
        value: 25
      },
      {
        name: '30 Menit',
        value: 30
      }
    ])
    const selectedOrderBy = ref('asc')
    const selectedOrderByName = ref('TERDEKAT')
    const refFilterOrderBy = ref()
    const orderByOption = ref([
      {
        name: 'TERDEKAT',
        value: 'asc'
      },
      {
        name: 'TERJAUH',
        value: 'desc'
      }
    ])
    const dataConfirmPackageReturn = ref<TDataConfirmPackageProp>()
    const refModalConfirmPackageReturn = ref<InstanceType<typeof ModalConfirmPackageReturn>>()
    const changePosition = (val: any) => {
      positionButton.value = val.key
    }

    const getItemCategory = async () => {
      const response = await getAllItemsKategoriBarang()
      const all = {
        value: -1,
        name: 'SEMUA'
      }
      filterCategoryList.value.push(all)
      if (!response.error) {
        // eslint-disable-next-line array-callback-return
        response.result.map((x: any) => {
          const temp = {
            value: x.Id,
            name: x.Nama
          }
          filterCategoryList.value.push(temp)
        })
      }
    }

    const getPickupItem = async (search: any) => {
      store.dispatch('showLoading')
      const tempListSchedule = await listCourierSchedule.value.filter((x: any) => x.IsCheck).map((x: any) => x.Id)
      newPackageUseCase.getPickup(tempListSchedule, search).then((response: any) => {
        if (!response.error) {
          listSchedule.value = response.result.Data.map((x: any) => {
            const listPickup = x.filter((pr: any) => pr.KurirMsUserId)
            x[0].IsPickup = listPickup.length > 0
            return {
              DataPengirim: x[0],
              ListItems: x.map((val: any) => {
                val.IdPaket = val.KurirTransUserPengirimanId
                return val
              })
            }
          })
        }
        store.dispatch('hideLoading')
      })
    }

    const getPickupItemNew = async () => {
      newPackageUseCase.getPickupNew().then((response: any) => {
        if (!response.error) {
          // eslint-disable-next-line no-use-before-define
          const AAA = filterSearch(response.result, submitSearchKey.value)
          const result = chain(AAA).groupBy((item) => [item.NamaPengirim, item.AlamatPengirim]).map((data, key) => ({ key, data })).value()
          // eslint-disable-next-line arrow-body-style
          listScheduleNew.value = result.map((val: any) => {
            const dataTemp = val.key
            const dataTempArray = dataTemp.split(',')
            const Pengirim = dataTempArray[0]
            const AlamatPengirim = dataTemp.replace(`${Pengirim},`, '')
            val.Id = val.data[0].Id
            val.Pengirim = Pengirim
            val.AlamatPengirim = AlamatPengirim
            val.AgenAsalNama = val.data[0].AgenAsalNama
            val.AgenTujuanNama = val.data[0].AgenTujuanNama
            val.KotaAsal = val.data[0].KotaAsalNama
            val.KotaTujuan = val.data[0].KotaTujuanNama
            val.HpPengirim = val.data[0].HpPengirim
            val.Status = val.data[0].StatusPengirimanId
            // eslint-disable-next-line no-use-before-define
            val.IsPickup = canMultipleAssign(val.data)
            return val
          })
          // eslint-disable-next-line no-use-before-define
          listScheduleNew.value = sortDataSource(listScheduleNew.value)
        }
      })
    }

    // eslint-disable-next-line consistent-return
    const filterSearch = (data: any, search: any = '') => {
      const dataCheckFilterCategory = ref([]) as any
      if (selectedFilterCategory.value >= 0) {
        for (let i = 0; i < data.length; i++) {
          for (let x = 0; x < data[i].TransPengirimanDetails.length; x++) {
            if (data[i].TransPengirimanDetails[x].KategoriBarangId === selectedFilterCategory.value) {
              dataCheckFilterCategory.value.push(data[i])
              break
            }
          }
        }
      } else {
        dataCheckFilterCategory.value = data
      }

      const dataAfterFilter = dataCheckFilterCategory.value.filter((y: any) => y.NamaPengirim.toLowerCase().includes(search.toLowerCase()) || y.Resi.toLowerCase().includes(search.toLowerCase()))
      return dataAfterFilter
    }

    const sortDataSource = (data: any) => {
      const adudu = data.map((x: any) => {
        if (selectedOrderBy.value === 'desc') {
          x.data = orderBy(x.data, (y: any) => y.WaktuMulaiPenjemputan, ['desc'])
        } else {
          x.data = orderBy(x.data, (y: any) => y.WaktuMulaiPenjemputan, ['asc'])
        }
        return x
      })

      return adudu
    }

    // eslint-disable-next-line consistent-return
    const canMultipleAssign = (data: any) => {
      const wasAssigned = ref(false)
      for (let i = 0; i < data.length; i++) {
        if (!isNull(data[i].KurirMsUserId)) {
          wasAssigned.value = true
          break
        }
      }
      return wasAssigned.value
    }

    /** Fungsi emit dri child FormAssigCourier */
    const reloadData = async (search: any = '') => {
      if (positionButton.value === 0) {
        // getPickupItem(search)
        getPickupItemNew()
      }
      // showCard.value = true
    }

    const submitSearchReceipt = async (search: any = '') => {
      submitSearchKey.value = search
      reloadData(search)
    }

    const showingSidebar = () => {
      sliderActive.value = true
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    /** Fungsi untuk single assign courier */
    const changeCourier = async (kurirId: any, transId: any, index: any) => {
      const data = {
        KurirMsUserId: kurirId,
        Paket: [{ IdPaket: transId }]
      }
      console.log('newPackage', data)
      const response = await newPackageUseCase.changeMultipleCourier(data)
      if (!response.error) {
        $toast.add({
          severity: 'success',
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 1500
        })
        // reloadData()
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 1500
        })
      }
    }

    const isShowDialogCourier = ref(false)
    const isShowDialogCancelPackage = ref(false)
    const dataTransferCourier: any = ref([])
    const fromPathChangeCourier: any = ref('')
    const hideModalChangeCouirer = () => {
      isShowDialogCourier.value = false
      dataTransferCourier.value = []
      fromPathChangeCourier.value = ''
    }

    const showModalAcceptCancelPackage = (val: any) => {
      requestedCancelPackage.value = val
      isShowDialogCancelPackage.value = true
    }
    const hideModalAcceptCancelPackage = () => {
      isShowDialogCancelPackage.value = false
    }

    // const transferCourier = async (index: any, data: any, from: any) => {
    //   await store.dispatch('showLoading')
    //   fromPathChangeCourier.value = from
    //   const filterKurirId = await data.filter((x: any) => x.KurirId === null)
    //   // const filterKurir = data.filter((x: any) => x.Kurir === null)
    //   if (await filterKurirId.length === 0) {
    //     await data.forEach(async (x: any) => {
    //       let Kurir: any = null
    //       if (await x.KurirId) {
    //         await fetchInfoCourier(x.KurirId).then(async (response: any) => {
    //           if (await response.length > 0) {
    //             Kurir = response[0]
    //           }
    //         })
    //       }
    //       x.Kurir = await Kurir
    //       x.Reason = await null
    //       dataTransferCourier.value.push(x)
    //     })
    //     isShowDialogCourier.value = true
    //   } else {
    //     $toast.add({
    //       severity: 'error',
    //       detail: 'Tidak bisa mengembalikan paket karena tidak ada kurir penjemput',
    //       group: 'bc',
    //       life: 1500
    //     })
    //   }
    //   store.dispatch('hideLoading')
    // }

    const submitTransferCourier = (index: any, newData: any) => {
      console.log('asee')
    }

    const multipleDataPackage = ref([])
    const isShowDialogPackage = ref(false)
    const packageReturn = async (data: any, index: any) => {
      store.dispatch('showLoading')
      console.log('data', data)
      const filterKurirId = await data.filter((x: any) => x.KurirId === null)
      // const filterKurir = data.filter((x: any) => x.Kurir === null)
      if (await filterKurirId.length === 0) {
        multipleDataPackage.value = await data.map((x: any) => {
          let Kurir: any = null
          // eslint-disable-next-line no-use-before-define
          fetchInfoCourier(x.KurirId).then(async (response: any) => {
            if (await response.length > 0) {
              Kurir = await response[0]
              x.Kurir = Kurir
              x.Reason = null
            }
          })
          return x
        })
        isShowDialogPackage.value = true
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Tidak bisa mengembalikan paket karena tidak ada kurir penjemput',
          group: 'bc',
          life: 1500
        })
      }
      store.dispatch('hideLoading')
    }
    const hideModalChangePackage = () => {
      isShowDialogPackage.value = false
      multipleDataPackage.value = []
    }
    const submitPackageReturns = async (data: any) => {
      store.dispatch('showLoading')
      const mapDataPaket = [] as any
      for (let i = 0; i < data.length; i++) {
        const x = data[i]
        let file = x.File[0].file
        file = await compressImage(file)
        file = await new File([file], file.name)
        let fileSend = null
        const formData = new FormData()
        formData.append('file', file)
        await receiveItemFileUseCase.submitFile('pengembalian', formData).then((res) => {
          if (!res.error) {
            fileSend = res.result.Filename
          }
        })
        mapDataPaket.push({
          IdPaket: x.IdPaket,
          Keterangan: x.Reason,
          Attribute1: fileSend,
        })
      }
      if (await mapDataPaket.length > 0) {
        const dataSend = await {
          // KurirMsUserId: data[0].KurirId,
          Paket: mapDataPaket
        }
        newPackageUseCase.changeMultiplePackageReturns(dataSend).then((response: any) => {
          if (!response.error) {
            $toast.add({
              severity: 'success',
              detail: response.result.detail ?? response.result.Detail,
              group: 'bc',
              life: 1500
            })
            hideModalChangePackage()
            reloadData()
          } else {
            $toast.add({
              severity: 'error',
              detail: response.message,
              group: 'bc',
              life: 1500
            })
          }
          store.dispatch('hideLoading')
        })
      }
    }

    const showDropDownRefreshTime = (evt: any) => {
      // refFilter.value.toggle(evt)
      refRefreshTime.value.toggle(evt)
    }

    const showDropDownFilterCategoryItem = (evt: any) => {
      // refFilter.value.toggle(evt)
      refFilterCategory.value.toggle(evt)
    }

    const showDropDownOrderBy = (evt: any) => {
      // refFilter.value.toggle(evt)
      refFilterOrderBy.value.toggle(evt)
    }

    const getJadwalKurir = () => {
      store.dispatch('showLoading')
      newPackageUseCase.getJadwalKurir({
        custom: `WaktuAkhirPenjemputan le ${moment().format('YYYY-MM-DD')}`,
        sorting: 'WaktuMulaiPenjemputan asc'
      }).then((response) => {
        if (!response.error) {
          listCourierSchedule.value = response.result.map((x: any) => {
            const pickUpEndTime = moment(x.WaktuAkhirPenjemputan)
            const now = moment()
            x.IsCheck = true
            x.Disabled = moment.duration(now.diff(pickUpEndTime)).asHours() > 0
            return x
          })
          // getPickupItem('')
          getPickupItemNew()
        }
        store.dispatch('hideLoading')
      })
    }

    const tempPickupData = ref(null) as any
    const assignCourierReadyPickup = (val: any) => {
      tempPickupData.value = val
      showingSidebar()
    }

    const fromChangeCourier = ref(null) as any
    // eslint-disable-next-line consistent-return
    const dataResiReturn = () => {
      if (positionButton.value === 0) {
        fromChangeCourier.value = 'pickup'
        const newList = tempPickupData.value
        return newList
      }
    }
    const fetchInfoCourier = async (idCourier: any) => {
      const response = await newPackageUseCase.getMicroInfo([{ Id: idCourier }])
      if (!response.error) {
        return response.result
      } return null
    }

    const transferCourier = async (index: any, data: any, from: any) => {
      await store.dispatch('showLoading')
      fromPathChangeCourier.value = from
      const filterKurirId = await data.filter((x: any) => x.KurirId === null)
      // const filterKurir = data.filter((x: any) => x.Kurir === null)
      if (await filterKurirId.length === 0) {
        await data.forEach(async (x: any) => {
          let Kurir: any = null
          if (await x.KurirId) {
            await fetchInfoCourier(x.KurirId).then(async (response: any) => {
              if (await response.length > 0) {
                Kurir = response[0]
              }
            })
          }
          x.Kurir = await Kurir
          x.Reason = await null
          dataTransferCourier.value.push(x)
        })
        isShowDialogCourier.value = true
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Tidak bisa mengembalikan paket karena tidak ada kurir penjemput',
          group: 'bc',
          life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const applyFilterPickupHours = () => {
      refFilter.value.hide()
      getPickupItem('')
    }

    const clearFilterPickupHours = () => {
      refFilter.value.hide()
      isAllListCourierSchedule.value = true
      getPickupItem('')
    }

    watch(isAllListCourierSchedule, (newVal: any, oldVal: any) => {
      for (let i = 0; i < listCourierSchedule.value.length; i++) {
        if (newVal) {
          listCourierSchedule.value[i].IsCheck = true
        } else {
          listCourierSchedule.value[i].IsCheck = false
        }
      }
    })

    watch(positionButton, (newVal: any) => {
      if (newVal === 0) {
        getJadwalKurir()
      }
    })

    watch(selectedRefreshTime, (val: any) => {
      setInterval(() => {
        // eslint-disable-next-line no-use-before-define
        refreshAction()
      }, (val * 60) * 1000)
    })

    watch(selectedFilterCategory, (val: any) => {
      // eslint-disable-next-line consistent-return
      const tempFilterCat = find(filterCategoryList.value, (x: any) => {
        if (x.value === val) {
          return x
        }
      })
      if (tempFilterCat !== undefined) {
        selectedFilterCategoryName.value = tempFilterCat.name
        reloadData('')
      }
    })

    watch(selectedOrderBy, (val: any) => {
      // eslint-disable-next-line consistent-return
      const tempOrderby = find(orderByOption.value, (x) => {
        if (x.value === val) {
          return x
        }
      }) as any
      if (tempOrderby) {
        selectedOrderByName.value = tempOrderby.name
        reloadData('')
      }
    })

    let intervalRefresh: any
    /** First load page */
    onMounted(() => {
      if (positionButton.value === 0) {
        getItemCategory()
        // eslint-disable-next-line no-use-before-define
        getDateNow()
        getJadwalKurir()
        intervalRefresh = setInterval(() => {
          // eslint-disable-next-line no-use-before-define
          refreshAction()
        }, (selectedRefreshTime.value * 60) * 1000)
      }
    })

    onUnmounted(() => clearInterval(intervalRefresh))

    const getDateNow = () => {
      refreshDate.value = `${moment().format('DD MMMM YYYY')} at ${moment().format('HH:mm:ss')}`
    }

    const refreshAction = () => {
      getDateNow()
      getJadwalKurir()
    }

    const onConfirmPackageReturn = (val: any) => {
      dataConfirmPackageReturn.value = {
        CourierId: val.KurirMsUserId,
        DeliveryId: val.Id
      }
      setTimeout(() => {
        refModalConfirmPackageReturn.value?.showDialog()
      }, 500)
    }

    return {
      positionButton,
      changePosition,
      store,
      dataFilter,
      showingSidebar,
      sliderActive,
      closeSidebar,
      reloadData,
      isShowDialogCourier,
      hideModalChangeCouirer,
      dataTransferCourier,
      fromPathChangeCourier,
      refFilter,
      showDropDownRefreshTime,
      moment,
      listCourierSchedule,
      isAllListCourierSchedule,
      listSchedule,
      dataResiReturn,
      assignCourierReadyPickup,
      fromChangeCourier,
      applyFilterPickupHours,
      clearFilterPickupHours,
      inputSearchReceipt,
      submitSearchReceipt,
      refreshDate,
      refreshAction,
      refRefreshTime,
      refreshDataoption,
      selectedRefreshTime,
      listScheduleNew,
      filterCategoryList,
      refFilterCategory,
      selectedFilterCategory,
      selectedFilterCategoryName,
      showDropDownFilterCategoryItem,
      selectedOrderBy,
      selectedOrderByName,
      refFilterOrderBy,
      orderByOption,
      showDropDownOrderBy,
      showModalAcceptCancelPackage,
      hideModalAcceptCancelPackage,
      isShowDialogCancelPackage,
      requestedCancelPackage,
      transferCourier,
      onConfirmPackageReturn,
      refModalConfirmPackageReturn,
      dataConfirmPackageReturn
    }
  }
})
