
/* eslint-disable consistent-return, no-else-return */
import {
  ref,
  getCurrentInstance,
  computed,
  onMounted,
  defineComponent,
  PropType
} from 'vue'
import {
  environment
} from '@/utils'
import {
  newPackageUseCase
} from '@/domain/usecase'
import {
  TDataPackageChangeCourierProp,
  TPathTransferCourier
} from '../../types/TPackageDelivery'

export default defineComponent({
  name: 'ModalChangeCourier',
  props: {
    isShowDialog: {
      type: Boolean,
      default: false
    },
    dataPackage: {
      type: Array as PropType<Array<TDataPackageChangeCourierProp>>,
      default: () => []
    },
    from: {
      type: String as PropType<TPathTransferCourier>
    }
  },
  emits: ['hideDialog', 'submitTransferCourier', 'reloadData'],
  setup(props, {
    emit
  }) {
    const app = getCurrentInstance()
    const {
      $toast,
    } = app!.appContext.config.globalProperties
    const hideDialog = () => {
      emit('hideDialog')
    }
    const API_BASE = environment.getApiBase()
    const reason = ref(null)
    // const isShowDialog = reactive(props.isShowDialog)
    // const dataPackage = reactive(props.dataPackage)
    const listPackage = ref(props.dataPackage)
    const fromPath = ref(props.from)
    const isShowModal = computed({
      get: () => props.isShowDialog,
      set: (val) => {
        if (!val) {
          hideDialog()
        }
      }
    })

    const dataTransferCourier = ref<Array<TDataPackageChangeCourierProp>>([])
    const isTransferCourier = () => {
      dataTransferCourier.value = listPackage.value.filter((x) => x.IsActive)
      if (dataTransferCourier.value.length > 0) {
        return true
      }
      return false
    }

    const dataChangeCourier = ref<Array<TDataPackageChangeCourierProp>>([])
    const isChangeCourier = () => {
      dataChangeCourier.value = listPackage.value.filter((x) => !x.IsActive)
      if (dataChangeCourier.value.length > 0) {
        return true
      } return false
    }

    const processMappingPackage = (list: Array<TDataPackageChangeCourierProp>) => {
      const mapArr = list.map((x) => ({
        IdPaket: x.Id
      }))
      return mapArr
    }

    const responseResult = (res: any) => {
      if (!res.error) {
        $toast.add({
          severity: 'success',
          detail: res.result.detail ?? res.result.Detail,
          group: 'bc',
          life: 2000
        })
        hideDialog()
        emit('reloadData')
      } else {
        $toast.add({
          severity: 'error',
          detail: res.message,
          group: 'bc',
          life: 2000
        })
      }
    }

    const processTransferOrChange = async () => {
      const dataSend = {
        Keterangan: reason.value,
        KurirMsUserId: listPackage.value[0].CourierReplacement.Id,
      } as any
      let response: any
      if (isTransferCourier()) {
        const listPackageTransferCourier = processMappingPackage(dataTransferCourier.value)
        dataSend.Paket = listPackageTransferCourier
        response = await newPackageUseCase.changeTransferCourier(dataSend)
        responseResult(response)
      }
      if (isChangeCourier()) {
        const listPackageChangeCourier = processMappingPackage(dataChangeCourier.value)
        dataSend.Paket = listPackageChangeCourier
        response = await newPackageUseCase.submitChangeCourier(dataSend)
        responseResult(response)
      }
    }

    const processSubmit = () => {
      if (fromPath.value === 'transfer-courier') {
        processTransferOrChange()
      } else if (fromPath.value === 'change-courier') {
        processTransferOrChange()
      }
    }
    const submit = () => {
      if (reason.value) {
        processSubmit()
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Pastikan alasan sudah diisi',
          group: 'bc',
          life: 1500
        })
      }
    }

    const labelReason = () => {
      if (fromPath.value === 'transfer-courier') {
        return 'Alasan Pengembalian'
      } else if (fromPath.value === 'change-courier') {
        return 'Alasan Penggantian Kurir'
      }
    }

    return {
      isShowModal,
      hideDialog,
      listPackage,
      API_BASE,
      reason,
      submit,
      fromPath,
      labelReason
    }
  }
})

