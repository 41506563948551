
/* eslint-disable no-unused-expressions, prefer-destructuring, no-restricted-syntax, no-await-in-loop */
import {
  defineComponent,
  ref,
  watch,
  reactive,
  computed,
  getCurrentInstance
} from 'vue'
import { useStore } from 'vuex'
import VirtualScroll from '@/views/shared-components/list/VirtualScroll.vue'
import { TPackageDeliver } from '@/data/source/remote/api/kurir/V2/packages-model'
import {
  ModalChangeCourier,
  ModalChangePackage,
  ModalConfirmPackageReturn
} from '@/views/monitoring-package/ready-deliver/components/modal'
import {
  CardReadyDeliverV2
} from '@/views/monitoring-package/ready-deliver/components/card'
import {
  FormAssignCourier
} from '@/views/monitoring-package/ready-deliver/components/form'
import { newPackageUseCase, receiveItemFileUseCase } from '@/domain/usecase'
import { TMicroInfoResult } from '@/data/source/remote/api/kurir/V1/msUsers-model'
import Sidebar from 'primevue/sidebar'
import { compressImage } from '@/plugins/compressor'
import type {
  TDataPackageDelivery,
  TParamDataChangeCourier,
  TPathTransferCourier,
  TDataPackageChangePackageProp,
  TDataPackageChangeCourierProp,
  TDataConfirmPackageProp
} from './types/TPackageDelivery'

const endpoint = 'kurir/v2/packages/deliveries'

export default defineComponent({
  name: 'ReadyDeliver',
  components: {
    VirtualScroll,
    CardReadyDeliverV2,
    ModalChangeCourier,
    ModalChangePackage,
    Sidebar,
    FormAssignCourier,
    ModalConfirmPackageReturn
  },
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const {
      $enumBase: enumBase,
      $icon: iconLocal,
      $toast
    } = app!.appContext.config.globalProperties
    const refVS = ref<InstanceType<typeof VirtualScroll>>()
    const refModalConfirmPackageReturn = ref<InstanceType<typeof ModalConfirmPackageReturn>>()
    const orderOption = ref([{
      label: 'Waktu Order asc',
      value: 'UpdatedAt asc'
    },
    {
      label: 'Waktu Order desc',
      value: 'UpdatedAt desc'
    },
    {
      label: 'Kurir Penjemput',
      value: 'CourierFullname desc'
    },
    {
      label: 'Barang Dibawa Kurir',
      value: 'IsActive desc'
    },
    {
      label: 'Barang Belum Discan',
      value: 'IsActive asc'
    }])
    const listOptionSearch = ref([
      {
        id: 'deliveryWaybill',
        name: 'No. Resi'
      },
      {
        id: 'deliverySenderName',
        name: 'Nama Pengirim'
      }
    ])
    const orderBy = ref('UpdatedAt asc')
    const inputSearch = ref('')
    const option = ref('deliveryWaybill')
    const profileAgent = store.getters['appActiveUser/getAgent']
    const paramList = reactive({
      endpoint,
      searchValue: computed(() => inputSearch.value),
      fieldSearch: computed(() => [`${option.value}`]),
      // filters: [['isDone', '=', 'false'], ['deliveryStatusCodes', '=', 'TDT,MKP,RTT,DKA,MKK,APK'], ['orderBy', '=', `${orderBy.value}`], ['deliveryDestAgentId', '=', `${profileAgent.id}`]]
      filters: [['isDone', '=', 'false'], ['deliveryStatusCodes', '=', 'TDT,MKP,RTT,DKA,MKK,APK'], ['orderBy', '=', `${orderBy.value}`], ['deliveryDestAgentId', '=', `${profileAgent.id}`], ['includeCityGroups', '=', 'true']]
    })
    const isActiveSidebar = ref(false)
    const tempData = ref<Array<TDataPackageDelivery>>([]) /** Variable ini digunakan untuk menampung data sementara dari list variable dataSource */
    const dataSource = ref<Array<TDataPackageDelivery>>([])
    const fromPathChangeCourier = ref<TPathTransferCourier>()
    const dataConfirmPackageReturn = ref<TDataConfirmPackageProp>()
    const isShowDialogCourier = ref(false)
    const isShowDialogPackage = ref(false)
    const dataTransferCourier = ref<Array<TDataPackageChangeCourierProp>>([])
    const deliveryWaybillChecked = computed<Array<TDataPackageDelivery>>(() => dataSource.value.filter((v) => v.IsCheck))

    /** Handling untuk List yang dicentang */
    const handleIsCheckInTempData = () => {
      tempData.value = []
      for (let index = 0; index < dataSource.value.length; index++) {
        if (dataSource.value[index]) {
          if (dataSource.value[index].IsCheck) {
            tempData.value.push(dataSource.value[index])
          }
        }
      }
    }

    /** Pengecekan jika dataSource mempunyai Id yang sama dengan tempData
     * maka Object di dataSource akan dihapus
     */
    const pushTempDataInDataSource = (data: TDataPackageDelivery) => {
      let same = false
      for (let index = 0; index < dataSource.value.length; index++) {
        if (dataSource.value[index].Id === data.Id) {
          same = true
          break
        }
      }
      if (!same) {
        dataSource.value.unshift(data)
      }
    }
    /** Memindahkan data temporary yang tersimpan ke posisi atas */
    const addTempDataOnTop = () => {
      for (let index = 0; index < tempData.value.length; index++) {
        pushTempDataInDataSource(tempData.value[index])
      }
    }

    // Pengecekan untuk data yang sama di dalam list
    const checkTheSameData = (data: TDataPackageDelivery) => {
      for (let index = 0; index < tempData.value.length; index++) {
        if (data.Id === tempData.value[index].Id) {
          return tempData.value[index]
        }
      }
      return data
    }

    const reloadData = () => {
      dataSource.value = []
      setTimeout(() => {
        refVS.value?.reloadData()
      }, 400)
    }

    const onSearch = () => {
      handleIsCheckInTempData()
      reloadData()
    }
    /** Pengecekan antara data source dengan data temporary
     * jika sama akan dilakukan push data ke variabel dataSource
     */
    const checkPushInDataSource = (data: TDataPackageDelivery) => {
      let same = false
      for (let index = 0; index < dataSource.value.length; index++) {
        if (dataSource.value[index]) {
          if (dataSource.value[index].Id === data.Id) {
            same = true
          }
        }
        if (same) {
          break
        }
      }
      if (!same) {
        if (data.IsCheck) {
          dataSource.value.unshift(data)
        } else {
          dataSource.value.push(data)
        }
      }
    }

    const mapperDataFromParent = (results: Array<TPackageDeliver>) => {
      /** Mapping list barang sesuai dengan kebutuhan */
      results.forEach((val) => {
        const mapObj: TDataPackageDelivery = {
          ...val,
          IsCheck: false,
          Courier: val?.CourierId ? {
            label: val?.CourierFullname,
            value: val?.CourierId
          } : null
        }
        /** Sending ke function checkTheSameData untuk pengecekan Id yang sama */
        const dataChecks = checkTheSameData(mapObj)
        /** Pushing ke variable yang digunakan untuk menampilkan listnya */
        checkPushInDataSource(dataChecks)
      })
      addTempDataOnTop()
    }

    const itemsFromVs = computed(() => refVS.value?.items as Array<TPackageDeliver>)

    watch(itemsFromVs, (items: Array<TPackageDeliver>) => {
      mapperDataFromParent(items)
    })

    /** Fungsi untuk single assign courier */
    const onChangeCourier = async (idNewCourier: number, idPackage: number, idx: number) => {
      const data = {
        KurirMsUserId: idNewCourier,
        Paket: [{ IdPaket: idPackage }]
      }
      const response = await newPackageUseCase.changeMultipleCourier(data)
      if (!response.error) {
        $toast.add({
          severity: 'success',
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 1500
        })
        reloadData()
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 1500
        })
      }
    }

    const fetchInfoCourier = async (idCourier: number): Promise<TMicroInfoResult[]> => {
      const {
        result,
        error
      } = await newPackageUseCase.getMicroInfo([{ Id: idCourier }])
      if (!error) {
        return result
      } return []
    }

    const multipleDataPackage = ref<TDataPackageChangePackageProp[]>([])
    const onPackageReturn = async (data: TDataPackageDelivery[], idx: number) => {
      store.dispatch('showLoading')
      const filterKurirId = data.filter((x) => !x.CourierId)
      if (filterKurirId.length === 0) {
        const arrTemp: Array<TDataPackageChangePackageProp> = []
        for (const x of data) {
          let Courier = null as TMicroInfoResult | null
          if (x.CourierId) {
            const result = await fetchInfoCourier(x.CourierId)
            if (result.length > 0) {
              Courier = result[0]
            }
          }
          const mapObj = {
            DeliveryWaybill: x.DeliveryWaybill,
            Reason: '',
            CourierProfile: Courier,
            File: [],
            Id: x.Id
          }
          arrTemp.push(mapObj)
        }
        multipleDataPackage.value = arrTemp
        isShowDialogPackage.value = true
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Tidak bisa mengembalikan paket karena tidak ada kurir penjemput',
          group: 'bc',
          life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const onTransferCourier = async (index: number, data: Array<TParamDataChangeCourier>, from: TPathTransferCourier) => {
      store.dispatch('showLoading')
      fromPathChangeCourier.value = from
      const filterKurirId = data.filter((x) => !x.CourierId)
      if (filterKurirId.length === 0) {
        data.forEach(async (x) => {
          let Courier = null as TMicroInfoResult | null
          if (x.CourierId) {
            await fetchInfoCourier(x.CourierId).then((response) => {
              if (response.length > 0) {
                Courier = response[0]
              }
            })
          }
          const mapData: TDataPackageChangeCourierProp = {
            ...x,
            Reason: '',
            CourierProfile: Courier
          }
          dataTransferCourier.value.push(mapData)
        })
        isShowDialogCourier.value = true
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Tidak bisa mengembalikan paket karena tidak ada kurir penjemput',
          group: 'bc',
          life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const hideModalChangeCouirer = () => {
      dataTransferCourier.value = []
      isShowDialogCourier.value = false
      fromPathChangeCourier.value = undefined
    }

    const hideModalChangePackage = () => {
      isShowDialogPackage.value = false
    }

    const submitPackageReturns = async (data: Array<TDataPackageChangePackageProp>) => {
      try {
        store.dispatch('showLoading')
        const mapDataPaket = [] as any
        for (const x of data) {
          let file = x.File[0].file
          file = await compressImage(file)
          file = new File([file], file.name)
          let fileSend = null
          const formData = new FormData()
          formData.append('file', file)
          const {
            result: resultSubmitFile,
            error: errSubmitFile
          } = await receiveItemFileUseCase.submitFile('pengembalian', formData)
          if (!errSubmitFile) {
            fileSend = await resultSubmitFile.Filename
          }
          mapDataPaket.push({
            IdPaket: x.Id,
            Keterangan: x.Reason,
            Attribute1: fileSend,
          })
        }
        if (mapDataPaket.length > 0) {
          const dataSend = {
            KurirMsUserId: data[0].CourierProfile?.Id,
            Paket: mapDataPaket
          }
          newPackageUseCase.changeMultiplePackageReturns(dataSend).then((response) => {
            if (!response.error) {
              $toast.add({
                severity: 'success',
                detail: response.result.detail ?? response.result.Detail,
                group: 'bc',
                life: 1500
              })
              hideModalChangePackage()
              reloadData()
            } else {
              $toast.add({
                severity: 'error',
                detail: response.message,
                group: 'bc',
                life: 1500
              })
            }
          })
        }
        store.dispatch('hideLoading')
      } catch (error) {
        store.dispatch('hideLoading')
      }
    }

    const closeSidebar = () => {
      isActiveSidebar.value = false
    }

    const showingSidebar = () => {
      isActiveSidebar.value = true
    }

    const onConfirmPackageReturn = (val: TDataPackageDelivery) => {
      dataConfirmPackageReturn.value = {
        CourierId: val.CourierId,
        DeliveryId: val.DeliveryId
      }
      setTimeout(() => {
        refModalConfirmPackageReturn.value?.showDialog()
      }, 500)
    }

    watch(orderBy, (val, oldVal) => {
      if (val !== oldVal) {
        const findFilter = paramList.filters.filter((v) => v[0] !== 'orderBy')
        let filterArr: string[][] = [...findFilter]
        if (val) {
          filterArr = [...findFilter, ['orderBy', '=', val]]
        }
        paramList.filters = filterArr
        reloadData()
      }
    })

    return {
      orderOption,
      orderBy,
      showingSidebar,
      inputSearch,
      listOptionSearch,
      endpoint,
      option,
      paramList,
      onSearch,
      mapperDataFromParent,
      dataSource,
      refVS,
      enumBase,
      iconLocal,
      onChangeCourier,
      onPackageReturn,
      onTransferCourier,
      isShowDialogCourier,
      hideModalChangeCouirer,
      reloadData,
      fromPathChangeCourier,
      dataTransferCourier,
      isShowDialogPackage,
      multipleDataPackage,
      hideModalChangePackage,
      submitPackageReturns,
      isActiveSidebar,
      deliveryWaybillChecked,
      closeSidebar,
      onConfirmPackageReturn,
      refModalConfirmPackageReturn,
      dataConfirmPackageReturn
    }
  }
})
