
/* eslint-disable vue/no-dupe-keys */
import {
  ref,
  reactive,
  toRefs,
  defineComponent
} from 'vue'
import type {
  PropType
} from 'vue'
import {
  VueEternalLoading
} from '@ts-pro/vue-eternal-loading'
import type {
  LoadAction
} from '@ts-pro/vue-eternal-loading'
import ProgressSpinner from 'primevue/progressspinner'
import apiUseCase from '@/domain/usecase/apiUseCase'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'

export default defineComponent({
  name: 'VirtualScroll',
  components: {
    ProgressSpinner,
    VueEternalLoading
  },
  props: {
    endpoint: {
      type: String,
      default: ''
    },
    totalRow: {
      type: Number,
      default: 10
    },
    fieldSearch: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    searchValue: {
      type: String,
      default: ''
    },
    filters: {
      type: Array as PropType<string[][]>,
      default: () => []
    },
    id: {
      type: String,
      default: 'id-virtual-scroll'
    }
  },
  setup(props) {
    const {
      endpoint,
      totalRow,
      searchValue,
      filters,
      fieldSearch,
      id
    } = toRefs(props)

    const items = ref<any[]>([])

    const paginate = reactive({
      pageSize: totalRow.value,
      pageNumber: 1,
      totalRecords: 0,
    })

    const tempLengthOfList = ref(0)
    const getAllData = async (reset = false) => {
      const {
        result,
        error
      } = await apiUseCase.get(`${endpoint.value}${returnUrlPrams({
        pageNumber: paginate.pageNumber,
        pageSize: paginate.pageSize,
        search: searchValue.value,
        custom: filters.value,
        filterField: fieldSearch.value
      })}`)
      if (!error) {
        if (reset) {
          items.value = []
        }
        tempLengthOfList.value = result.length
        items.value = [...items.value, ...result]
        paginate.pageNumber += 1
      }
    }

    const isInitial = ref(false)
    /** Fungsi emit dri child FormAssigCourier */
    const reloadData = async () => {
      // store.dispatch('showLoading')
      paginate.pageNumber = 0
      await getAllData(true)
      isInitial.value = true
      // store.dispatch('hideLoading')
    }

    /** Menggunakan load data untuk plugin pagination */
    const loadData = async ({ loaded, noMore }: LoadAction) => {
      getAllData(isInitial.value).then(() => {
        if (tempLengthOfList.value < paginate.pageSize) {
          /** menggunakan fungsi unutuk mereset state data kosong type dri interface plugin vue-eternal-loading */
          noMore()
        } else {
          /** menggunakan fungsi unutuk load state type dri interface plugin vue-eternal-loading */
          loaded()
        }
      })
    }

    return {
      items,
      loadData,
      isInitial,
      reloadData,
      id
    }
  }
})
