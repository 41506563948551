
import {
  ref,
  reactive,
  toRefs,
  defineComponent
} from 'vue'
import moment from 'moment'

export default defineComponent({
  name: 'CardReadyPickUp',
  props: {
    data: {
      default: () => Object
    }
  },
  emits: ['assignCourier', 'showModalAcceptCancelPackage', 'confirmPackageReturn'],
  setup(props, { emit }) {
    const courierBy = ref(null)
    const op = ref()
    // eslint-disable-next-line prefer-destructuring
    const { data } = toRefs(props)
    const checking = ref(true)
    const dataForm = ref(null)
    const showDropDown = (evt: any, val: any) => {
      dataForm.value = val
      op.value.toggle(evt)
    }
    const toTelp = (tel: any) => {
      window.location.href = `tel:+${tel}`
    }
    const assignCourier = () => {
      emit('assignCourier', [dataForm.value])
    }
    const pickupAll = (val: any) => {
      emit('assignCourier', val)
    }
    const showModalAcceptCancelPackage = (val: any) => {
      emit('showModalAcceptCancelPackage', val)
    }
    const onConfirmPackageReturn = (val: any) => {
      emit('confirmPackageReturn', val)
    }
    return {
      courierBy,
      checking,
      // eslint-disable-next-line vue/no-dupe-keys
      data,
      showDropDown,
      op,
      moment,
      toTelp,
      dataForm,
      assignCourier,
      pickupAll,
      showModalAcceptCancelPackage,
      onConfirmPackageReturn
    }
  }
})
