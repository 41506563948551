
/* eslint-disable no-unused-expressions */
import {
  ref,
  toRefs,
  getCurrentInstance,
  defineComponent,
  reactive
} from 'vue'
import type {
  PropType
} from 'vue'
import {
  useStore
} from 'vuex'
import {
  environment
} from '@/utils'
import {
  cloneDeep,
  isEmpty
} from 'lodash'
import { useRoute } from 'vue-router'
import moment from 'moment'
import OverlayPanel from 'primevue/overlaypanel'
import SelectAsync from '@/views/shared-components/select/MultiselectAsync.vue'
import VirtualScroll from '@/views/shared-components/list/VirtualScroll.vue'
import { TListAssignCourier } from '@/data/source/remote/api/kurir/V1/msUsers-model'
import { TObjSelect } from '@/utils/types/tOptionSelect'
import {
  TDataPackageDelivery,
  TParamDataChangeCourier,
  TPathTransferCourier
} from '../../types/TPackageDelivery'

const endpointAssignCourier = '/kurir/v1/MsUsers/ListAssign'

export default defineComponent({
  name: 'CardReadyDeliverV2',
  components: {
    OverlayPanel,
    SelectAsync,
    VirtualScroll
  },
  props: {
    /** Atribute untuk object data untuk ditampilkan pada card */
    data: {
      type: Object as PropType<TDataPackageDelivery>,
      default: null
    },
    /** Attribute untuk mendapatkan index dri looping */
    index: {
      type: Number,
      default: 0,
      require
    },
    useSelectQourier: {
      default: () => true
    },
    isUseScheduleBtn: {
      default: () => false
    },
    /** Attribute yang digunakan untuk mendapatkan list courier */
    courierOptions: {
      default: () => Array
    }
  },
  emits: ['settingSchedule', 'changeCourier', 'packageReturn', 'transferCourier', 'toManifest', 'showDetailResiDialog', 'confirmPackageReturn'],
  setup(props, { emit }) {
    const courierBy = ref(null)
    const app = getCurrentInstance()
    const {
      $toast,
      $numbers
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const route = useRoute() as any
    // const {
    //   data
    // } = reactive(props)
    // eslint-disable-next-line prefer-destructuring
    const {
      data,
      index
    } = toRefs(props)
    const profileAgent = reactive(store.getters['appActiveUser/getAgent'])
    const checking = ref(true)
    const inputSelectCourier = ref()
    const refOp = ref<InstanceType<typeof OverlayPanel>>()
    const refPanelListCourier = ref<InstanceType<typeof OverlayPanel>>()
    const typeEvent = ref<TPathTransferCourier>()
    const API_BASE = environment.getApiBase()
    const paramCourierAssign = reactive({
      endpoint: endpointAssignCourier,
      filters: [['AgenId', '=', profileAgent?.id]],
      fieldLabel: 'Fullname',
      searchFields: ['Fullname'],
      minChars: 0,
      canDeselect: false
    })
    const paramListCourier = reactive({
      endpoint: endpointAssignCourier,
      filters: [['AgenId', '=', profileAgent?.id]]
    })

    const scheduleButtonAct = (selectedData: any) => {
      emit('settingSchedule', selectedData)
    }
    const toManifestAct = (selectedData: any) => {
      emit('toManifest', selectedData)
    }

    const selectCourier = (newSubsCourier: TListAssignCourier) => {
      const cloneExistingData = cloneDeep(data.value)
      const newDataCourier: TParamDataChangeCourier = {
        ...cloneExistingData,
        CourierReplacement: newSubsCourier
      }
      emit('transferCourier', index.value, [newDataCourier], typeEvent.value)
      refPanelListCourier.value?.hide()
      typeEvent.value = undefined
    }

    const onPackageReturn = () => {
      emit('packageReturn', [data.value], index.value)
    }

    const showDropDown = (evt: any) => {
      refOp.value?.toggle(evt)
    }

    /** Fungsi emit change dri MultiSelect dengan
     * params yang berisi Id Kurir yang telah dipilih */
    const onChangeCourier = (courier: TListAssignCourier & TObjSelect) => {
      const {
        Courier
      } = data.value
      const CourierId = courier.value
      if (isEmpty(courier)) {
        data.value.Courier = Courier
        $toast.add({
          severity: 'error',
          detail: 'Data tidak boleh kosong',
          group: 'bc',
          life: 1500
        })
      } else {
        /** Send emit to parent component with params :
         * 1. Id Kurir yang dipilih,
         * 2. IdPaket yang telah dipilih, dan
         * 3. index dri list yang dipilih
         * */
        emit('changeCourier', CourierId, data.value?.Id, index.value)
      }
    }

    const onClickSelect = (event: Event) => {
      const {
        update
      } = route?.meta?.permission
      if (data.value.CourierId && !data.value.IsActive && update === true) {
        typeEvent.value = 'change-courier'
        refPanelListCourier.value?.toggle(event)
      }
    }

    const onTransferCourier = (event: Event) => {
      typeEvent.value = 'transfer-courier'
      refOp.value?.hide()
      refPanelListCourier.value?.toggle(event)
    }

    const showDetailResiDialogAct = (val: any) => {
      emit('showDetailResiDialog', val)
    }

    const onConfirmPackageReturn = () => {
      emit('confirmPackageReturn', data.value)
    }

    return {
      courierBy,
      checking,
      /* eslint-disable vue/no-dupe-keys */
      data,
      scheduleButtonAct,
      // courierOptions,
      selectCourier,
      index,
      onChangeCourier,
      API_BASE,
      showDropDown,
      refOp,
      onTransferCourier,
      onPackageReturn,
      refPanelListCourier,
      inputSelectCourier,
      onClickSelect,
      route,
      moment,
      toManifestAct,
      showDetailResiDialogAct,
      paramCourierAssign,
      paramListCourier,
      onConfirmPackageReturn
    }
  }
})

