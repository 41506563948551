import {
  toRefs,
  ref,
  onMounted,
  getCurrentInstance,
  computed
} from 'vue'
import {
  useRoute
} from 'vue-router'
import {
  useStore
} from 'vuex'
import CourierProfileCard from '@/views/master/courier/TrackingComponent/CourierProfileCard.vue'
import {
  newPackageUseCase,
  courierUseCase
} from '@/domain/usecase'
import cloneDeep from 'lodash'

export default {
  name: 'FormAssignCourier',
  components: {
    CourierProfileCard
  },
  props: {
    dataResi: {
      default: () => []
    },
    title: {
      default: () => null
    },
    from: {
      // pickup untuk tab yang penjadwalan
      // deliver untuk tab yang dikirim
      default: () => null
    }
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const route = useRoute()
    const store = useStore()
    const app = getCurrentInstance()
    const {
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const inputSearch = ref('')
    const {
      dataResi,
      title,
      from
    } = toRefs(props)
    const courierOptions = ref([]) as any
    const filtersCourierOption = computed(() => courierOptions.value.filter((item: any) => item.Nama.toLowerCase().includes(inputSearch.value.toLowerCase())))
    const closeSidebar = () => {
      emit('closeSidebar')
    }
    const reloadData = () => {
      emit('reloadData')
    }

    const isCanSubmit = () => {
      // const canSubmit = await dataSource.value.indexOf((obj: any) => obj.StatusPengecekan.CanChange === false)
      console.log('data resi', dataResi.value)
      // console.log('kurirId', x.kurirId)
      const canSubmit = dataResi.value.filter((x: any) => x.KurirMsUserId ?? x.KurirId)
      console.log('can submit', canSubmit)
      if (canSubmit.length > 0) {
        return true
      }
      return false
    }

    const processTransferCouirer = (newSubsCourier: any) => {
      if (isCanSubmit()) {
        newSubsCourier.Fullname = newSubsCourier.Nama
        const newDataCourier = dataResi.value.map((x: any) => {
          x.KurirPengganti = newSubsCourier
          return x
        })
        emit('transferCourier', 0, newDataCourier, 'transfer-kurir')
        closeSidebar()
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Untuk mengganti kurir pastikan setiap resi sudah mempunyai kurir penjemput!!',
          group: 'bc',
          life: 3000
        })
      }
    }

    /** Sending multiple paket ke kurir yang dipilih */
    const assignCourier = async (value: any) => {
      store.dispatch('showLoading')
      console.log('formVal', value)
      // if (from.value === 'pickup') {
      //   console.log('why pick up')
      // } if (from.value === 'deliver') {
      if (isCanSubmit()) {
        console.log('xxx')
        processTransferCouirer(value)
      } else {
        console.log('yyy')
        // console.log(dataResi.value[0].includes('KurirTransUserPengirimanId'))
        // console.log(!('KurirTransUserPengirimanId' in dataResi.value[0]))
        const idResi = dataResi.value.map((x: any) => ({
          // IdPaket: x.IdPaket
          IdPaket: !('KurirTransUserPengirimanId' in x) ? x.IdPaket : x.KurirTransUserPengirimanId
        }))
        // console.log('idResi', idResi)
        const data = {
          KurirMsUserId: value.Id,
          Paket: idResi
        }
        console.log('formAssign', data)
        const response = await newPackageUseCase.changeMultipleCourier(data)
        if (!response.error) {
          $toast.add({
            severity: 'success',
            detail: response.result.detail ?? response.result.Detail,
            group: 'bc',
            life: 1500
          })
          reloadData()
          closeSidebar()
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 1500
          })
        }
      }
      // }
      store.dispatch('hideLoading')
    }

    /** GetData courier dri api */
    const getDataCourier = async () => {
      store.dispatch('showLoading')
      const response = await courierUseCase.getListAssignCourier({
        // PageNumber: `?PageNumber=${filter.skip}`,
        // PageSize: `&PageSize=${filter.top}`
        AgenId: `?AgenId=${store.getters['appActiveUser/getAgent']?.id}`,
        // KurirName: inputSearch.value
      })
      if (!response.error) {
        // eslint-disable-next-line arrow-body-style
        courierOptions.value = response.result.Data.map((x: any) => {
          return {
            Id: x.Id,
            Nama: x.Fullname,
            Handphone: x.Phone,
            PackageCount: x.PackageCount,
            PackageWeight: x.PackageWeight,
            Picture: x.Picture,
            Kapasitas: x.Kendaraan?.KurirMsJenisKendaraan?.Kapasitas
          }
        })
      } else {
        $toast.add({
          severity: 'error',
          detail: $strInd.toast.errorAllData,
          group: 'bc',
          life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    /** searching */
    const submitSearch = async (val: any) => {
      console.log('search', val)
      // getDataCourier()
      // const tempCourierOptions = await courierOptions.value.filter((item: any) => item.Nama.toLowerCase().includes(val.toLowerCase()))
      // courierOptions.value = tempCourierOptions
      // console.log('ini courir', tempCourierOptions)
      // console.log('lklklk', courierOptions.value)
    }
    onMounted(() => {
      console.log(`where i from ${from.value}`, dataResi.value)
      getDataCourier()
    })
    return {
      route,
      store,
      /* eslint-disable vue/no-dupe-keys */
      dataResi,
      title,
      closeSidebar,
      reloadData,
      submitSearch,
      inputSearch,
      courierOptions,
      assignCourier,
      filtersCourierOption
    }
  }
}
