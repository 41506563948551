/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import {
  ref,
  getCurrentInstance,
  reactive,
  computed,
  onMounted
} from 'vue'
import {
  environment
} from '@/utils'
import {
  cancelPackageUseCase
} from '@/domain/usecase'
import { useStore } from 'vuex'

export default {
  name: 'ModalChangeCourier',
  props: {
    isShowDialog: {
      default: () => false
    },
    idPackage: {
      default: () => null
    }
  },
  emits: ['hideDialog', 'submitTransferCourier', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $toast,
    } = app!.appContext.config.globalProperties
    const hideDialog = () => {
      emit('hideDialog')
    }
    const API_BASE = environment.getApiBase()
    const store = useStore()
    const propertiesProps = reactive(props)
    const reason = ref(null)
    const packageId = ref(propertiesProps.idPackage)
    const dataSource = ref(null) as any
    const isShowModal = computed({
      get: () => propertiesProps.isShowDialog,
      set: (val) => {
        if (!val) {
          hideDialog()
        }
      }
    })

    const processMappingPackage = (list: any) => {
      const mapArr = list.map((x: any) => ({
        IdPaket: x.IdPaket
      }))
      return mapArr
    }

    const responseResult = (res: any) => {
      if (!res.error) {
        $toast.add({
          severity: 'success',
          detail: res.result.detail ?? res.result.Detail,
          group: 'bc',
          life: 2000
        })
        hideDialog()
        emit('reloadData')
      } else {
        $toast.add({
          severity: 'error',
          detail: res.message,
          group: 'bc',
          life: 2000
        })
      }
    }

    // const processTransferOrChange = async () => {
    //   const dataSend = {
    //     Keterangan: reason.value,
    //     KurirMsUserId: listPackage.value[0].KurirPengganti.Id,
    //   } as any
    //   let response: any
    //   if (isTransferCourier()) {
    //     const listPackageTransferCourier = processMappingPackage(dataTransferCourier.value)
    //     dataSend.Paket = listPackageTransferCourier
    //     response = await newPackageUseCase.changeTransferCourier(dataSend)
    //     responseResult(response)
    //   }
    //   if (isChangeCourier()) {
    //     const listPackageChangeCourier = processMappingPackage(dataChangeCourier.value)
    //     dataSend.Paket = listPackageChangeCourier
    //     response = await newPackageUseCase.submitChangeCourier(dataSend)
    //     responseResult(response)
    //   }
    // }

    // const processSubmit = () => {
    //   if (fromPath.value === 'transfer-kurir') {
    //     processTransferOrChange()
    //   } else if (fromPath.value === 'ganti-kurir') {
    //     processTransferOrChange()
    //   }
    // }
    // const submit = () => {
    //   if (reason.value) {
    //     processSubmit()
    //   } else {
    //     $toast.add({
    //       severity: 'error',
    //       detail: 'Pastikan alasan sudah diisi',
    //       group: 'bc',
    //       life: 1500
    //     })
    //   }
    // }

    const submit = async () => {
      store.dispatch('showLoading')
      const response = await cancelPackageUseCase.acknowledgeCancelPackage(packageId.value)
      if (!response.error) {
        emit('reloadData')
        $toast.add({
          severity: 'success', summary: 'Konfirmasi Pemabatalan Paket berhasil !', detail: 'Resi telah diteruskan ke Admin', group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'error', detail: 'Konfirmasi gagal !', group: 'bc', life: 1500
        })
      }
      hideDialog()
      store.dispatch('hideLoading')
    }

    const getPackageData = async () => {
      store.dispatch('showLoading')
      const response = await cancelPackageUseCase.getOne(packageId.value)
      if (!response.error) {
        dataSource.value = response.result
        console.log('data', dataSource.value.Id)
      } else {
        hideDialog()
        $toast.add({
          severity: 'error', detail: 'Data tidak ditemukan !', group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    onMounted(() => {
      console.log('selectedId', propertiesProps.idPackage)
      getPackageData()
    })

    return {
      isShowModal,
      hideDialog,
      API_BASE,
      dataSource,
      submit,
    }
  }
}
