
/* eslint-disable vue/no-dupe-keys, no-unused-expressions */
import {
  PropType,
  defineComponent,
  getCurrentInstance,
  ref,
  toRefs,
  watch
} from 'vue'
import Dialog from 'primevue/dialog'
import Avatar from 'primevue/avatar'
import Lightgallery, { IImg } from '@/views/shared-components/lightgallery/index.vue'
import DialogConfirm from '@/views/shared-components/dialog/Confirm.vue'
import { readyDeliverUseCase } from '@/domain/usecase'
import { TMicroInfoResult } from '@/data/source/remote/api/kurir/V1/msUsers-model'
import { TReturnRequestDetail } from '@/data/source/remote/api/backend/V2/transPengirimanHds-model'
import { environment } from '@/utils'
import { useStore } from 'vuex'
import {
  TDataConfirmPackageProp
} from '../../types/TPackageDelivery'

type TDataReturnPackage = Omit<TReturnRequestDetail, 'Documents'> & {
  Documents: Array<IImg>
}

export default defineComponent({
  name: 'ModalConfirmPackageReturn',
  components: {
    Dialog,
    Avatar,
    Lightgallery,
    DialogConfirm
  },
  props: {
    dataPackage: {
      type: Object as PropType<TDataConfirmPackageProp>
    }
  },
  emits: ['reloadData'],
  setup(props, { emit }) {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const {
      dataPackage
    } = toRefs(props)
    const store = useStore()
    const isShowDialog = ref(false)
    const isLoading = ref(false)
    const dataCourier = ref<TMicroInfoResult | null>(null)
    const dataReturnRequest = ref<TDataReturnPackage>()
    const refDialogConfirm = ref<InstanceType<typeof DialogConfirm>>()
    const API_BASE = environment.getApiBase()

    const showDialog = () => {
      isShowDialog.value = true
    }
    const hideDialog = () => {
      isShowDialog.value = false
    }

    const getDetailCourier = async () => {
      const result = await readyDeliverUseCase.getSingleMicroInfo(dataPackage?.value?.CourierId as number)
      dataCourier.value = result
    }

    const getDetailPackage = async () => {
      const {
        error,
        result
      } = await readyDeliverUseCase.getDetailReturnPackage(dataPackage?.value?.DeliveryId?.toString() as string)
      if (!error) {
        dataReturnRequest.value = {
          ...result,
          Documents: result.Documents.map((v, i) => ({
            src: v.Filename ? `${environment.getApiBase()}${v.Filename}` : '',
            alt: `document-${i}`,
            file: v.Filename ? `${environment.getApiBase()}${v.Filename}` : ''
          }))
        }
      }
    }
    const getDetailData = async () => {
      isLoading.value = true
      await Promise.all([
        getDetailCourier(),
        getDetailPackage()
      ])
      isLoading.value = false
    }

    watch(isShowDialog, (val) => {
      if (val) {
        getDetailData()
      }
    })

    const doConfirmPackage = async () => {
      isLoading.value = true
      const {
        error,
        message
      } = await readyDeliverUseCase.approveReturnPackage(dataPackage?.value?.DeliveryId?.toString() as string)
      if (!error) {
        $toast.add({
          severity: 'success',
          detail: message,
          group: 'bc',
          life: 3000
        })
        emit('reloadData')
        hideDialog()
      } else {
        $toast.add({
          severity: 'error',
          detail: message,
          group: 'bc',
          life: 3000
        })
      }
      isLoading.value = false
    }

    const onConfirm = () => {
      refDialogConfirm.value?.openModal({
        imgContent: require('@/assets/img/confirmation/package-return.png'),
        btnBackTitle: 'Batalkan',
        btnSubmitTitle: 'Konfirmasi Pengembalian Paket',
        subTitle: 'Dengan mengembalikan paket, anda diwajibkan untuk assign ulang kurir untuk pengantaran barang ke customer, pastikan paket telah diterima oleh agen',
        title: 'Konfirmasi Pengembalian Paket'
      }).then((isConifrm) => {
        if (isConifrm) {
          doConfirmPackage()
        }
      })
    }

    const doDeclinePackage = async () => {
      isLoading.value = true
      const {
        error,
        message
      } = await readyDeliverUseCase.declineReturnPackage(dataPackage?.value?.DeliveryId?.toString() as string)
      if (!error) {
        $toast.add({
          severity: 'success',
          detail: message,
          group: 'bc',
          life: 1500
        })
        emit('reloadData')
        hideDialog()
      } else {
        $toast.add({
          severity: 'error',
          detail: message,
          group: 'bc',
          life: 1500
        })
      }
      isLoading.value = false
    }

    const onDecline = () => {
      refDialogConfirm.value?.openModal({
        imgContent: require('@/assets/img/confirmation/package-return-nonactive.png'),
        btnBackTitle: 'Batalkan',
        btnSubmitTitle: 'Konfirmasi Penolakan Pengembalian Paket',
        subTitle: 'Dengan menolak pengembalian paket, pengantar paket akan tetap oleh kurir yang bersangkutan',
        title: 'Konfirmasi Penolakan Pengembalian Paket'
      }).then((isConifrm) => {
        if (isConifrm) {
          doDeclinePackage()
        }
      })
    }

    watch(isLoading, (val) => {
      if (val) {
        store.dispatch('showLoading')
      } else {
        store.dispatch('hideLoading')
      }
    })

    return {
      isShowDialog,
      showDialog,
      hideDialog,
      dataPackage,
      dataCourier,
      dataReturnRequest,
      API_BASE,
      isLoading,
      onConfirm,
      onDecline,
      refDialogConfirm
    }
  },
})
