import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "type-bus-form flex-col flex relative h-full px-3" }
const _hoisted_2 = { class: "title-form py-5 mb-6 flex items-center justify-between" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = { class: "section-list-courier" }
const _hoisted_7 = { class: "p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputIconRightGrey = _resolveComponent("InputIconRightGrey")
  const _component_CourierProfileCard = _resolveComponent("CourierProfileCard")
  const _component_VirtualScroll = _resolveComponent("VirtualScroll")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("h6", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createVNode("i", {
        class: "pi pi-times cursor-pointer",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeSidebar && _ctx.closeSidebar(...args)))
      })
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode("div", _hoisted_5, [
        _createVNode(_component_InputIconRightGrey, {
          placeholder: "Cari Disini ...",
          type: _ctx.$enumBase.input.text,
          modelValue: _ctx.inputSearch,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.inputSearch = $event)),
          canClick: true,
          onSubmitInput: _ctx.submitSearch,
          onKeyup: _withKeys(_ctx.submitSearch, ["enter"]),
          icon: _ctx.$icon.svg.searchEva
        }, null, 8, ["type", "modelValue", "onSubmitInput", "onKeyup", "icon"])
      ]),
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_VirtualScroll, _mergeProps(_ctx.paramListCourier, { ref: "refVs" }), {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_7, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.courierOptions, (data, index) => {
                return (_openBlock(), _createBlock(_component_CourierProfileCard, {
                  data: data,
                  key: index,
                  class: "mb-3",
                  isAssign: true,
                  onAssign: _ctx.assignCourier
                }, null, 8, ["data", "onAssign"]))
              }), 128))
            ])
          ]),
          _: 1
        }, 16)
      ])
    ])
  ]))
}