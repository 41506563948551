
/* eslint-disable no-unused-expressions */
import {
  toRefs,
  ref,
  onMounted,
  getCurrentInstance,
  computed,
  defineComponent,
  PropType,
  reactive,
  watch
} from 'vue'
import {
  useRoute
} from 'vue-router'
import {
  useStore
} from 'vuex'
import CourierProfileCard from '@/views/master/courier/TrackingComponent/CourierProfileCard.vue'
import {
  newPackageUseCase,
  courierUseCase
} from '@/domain/usecase'
import VirtualScroll from '@/views/shared-components/list/VirtualScroll.vue'
import { TListAssignCourier } from '@/data/source/remote/api/kurir/V1/msUsers-model'
import { TDataCourierProfileCardProp } from '@/views/master/courier/TrackingComponent/TCourierProfileCardProps'
import { TDataPackageDelivery, TParamDataChangeCourier } from '../../types/TPackageDelivery'

export default defineComponent({
  name: 'FormAssignCourier',
  components: {
    CourierProfileCard,
    VirtualScroll
  },
  props: {
    deliveryWaybillChecked: {
      type: Array as PropType<Array<TDataPackageDelivery>>,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    from: {
      // pickup untuk tab yang penjadwalan
      // deliver untuk tab yang dikirim
      default: () => null
    }
  },
  emits: ['closeSidebar', 'reloadData', 'transferCourier'],
  setup(props, {
    emit
  }) {
    const route = useRoute()
    const store = useStore()
    const app = getCurrentInstance()
    const {
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const refVs = ref<InstanceType<typeof VirtualScroll>>()
    const inputSearch = ref('')
    const {
      deliveryWaybillChecked,
      title
    } = toRefs(props)
    const paramListCourier = reactive({
      id: 'virtual-scroll-asign-courier',
      endpoint: '/kurir/v1/MsUsers/ListAssign',
      filters: [['AgenId', '=', store.getters['appActiveUser/getAgent']?.id]],
      fieldSearch: ['Fullname'],
      searchValue: computed(() => inputSearch.value)
    })
    const courierOptions = ref<Array<TDataCourierProfileCardProp>>([])
    const closeSidebar = () => {
      emit('closeSidebar')
    }
    const reloadData = () => {
      emit('reloadData')
    }

    const isCanSubmit = () => {
      const canSubmit = deliveryWaybillChecked.value.filter((x) => x.CourierId)
      if (canSubmit.length > 0) {
        return true
      }
      return false
    }

    const processTransferCouirer = (newSubsCourier: TDataCourierProfileCardProp) => {
      if (isCanSubmit()) {
        const newDataCourier: TParamDataChangeCourier[] = deliveryWaybillChecked.value.map((x) => {
          const mapData: TParamDataChangeCourier = {
            ...x,
            CourierReplacement: newSubsCourier as TListAssignCourier
          }
          return mapData
        })
        emit('transferCourier', 0, newDataCourier, 'transfer-courier')
        closeSidebar()
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Untuk mengganti kurir pastikan setiap resi sudah mempunyai kurir penjemput!!',
          group: 'bc',
          life: 3000
        })
      }
    }

    /** Sending multiple paket ke kurir yang dipilih */
    const assignCourier = async (value: TDataCourierProfileCardProp) => {
      store.dispatch('showLoading')
      if (isCanSubmit()) {
        processTransferCouirer(value)
      } else {
        const idResi = deliveryWaybillChecked.value.map((x) => ({
          IdPaket: x.Id
        }))
        const data = {
          KurirMsUserId: value.Id,
          Paket: idResi
        }
        const response = await newPackageUseCase.changeMultipleCourier(data)
        if (!response.error) {
          $toast.add({
            severity: 'success',
            detail: response.result.detail ?? response.result.Detail,
            group: 'bc',
            life: 1500
          })
          reloadData()
          closeSidebar()
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 1500
          })
        }
      }
      store.dispatch('hideLoading')
    }

    const mapDataCourier = (items: TListAssignCourier[]) => {
      const mapper = items.map((v) => ({
        ...v,
        Nama: v.Fullname,
        Handphone: v.Phone,
        Kapasitas: v.Kendaraan?.KurirMsJenisKendaraan?.Kapasitas
      }))
      courierOptions.value = mapper
    }

    const itemsFromVs = computed(() => refVs.value?.items as Array<TListAssignCourier>)

    watch(itemsFromVs, (items: Array<TListAssignCourier>) => {
      mapDataCourier(items)
    })

    /** searching */
    const submitSearch = async () => {
      courierOptions.value = []
      setTimeout(() => {
        refVs.value?.reloadData()
      }, 500)
    }

    return {
      route,
      store,
      /* eslint-disable vue/no-dupe-keys */
      deliveryWaybillChecked,
      title,
      closeSidebar,
      reloadData,
      submitSearch,
      inputSearch,
      assignCourier,
      paramListCourier,
      mapDataCourier,
      refVs,
      courierOptions
    }
  }
})
