
import {
  ref,
  getCurrentInstance,
  reactive,
  computed,
  defineComponent,
  PropType
} from 'vue'
import VueUploadComponent from 'vue-upload-component'
import {
  environment
} from '@/utils'
import {
  TDataPackageChangePackageProp
} from '../../types/TPackageDelivery'

export default defineComponent({
  name: 'ModalChangePackage',
  components: {
    FileUpload: VueUploadComponent
  },
  props: {
    isShowDialog: {
      type: Boolean,
      default: false
    },
    dataPackage: {
      type: Array as PropType<Array<TDataPackageChangePackageProp>>,
      default: () => []
    }
  },
  emits: ['hideDialog', 'submitPackageReturns'],
  setup(props, {
    emit
  }) {
    const app = getCurrentInstance()
    const {
      $toast,
    } = app!.appContext.config.globalProperties
    const hideDialog = () => {
      emit('hideDialog')
    }
    const API_BASE = environment.getApiBase()
    const listPackage = ref(props.dataPackage)
    const isShowModal = computed({
      get: () => props.isShowDialog,
      set: (val) => {
        if (!val) {
          hideDialog()
        }
      }
    })
    const checkingFile = () => {
      const canSubmit = listPackage.value.filter((x: any) => x.File.length === 0)
      if (canSubmit.length === 0) {
        return true
      }
      return false
    }
    const isCanSubmit = () => {
      // const canSubmit = await dataSource.value.indexOf((obj: any) => obj.StatusPengecekan.CanChange === false)
      const canSubmit = listPackage.value.filter((x: any) => !x.Reason)
      if (canSubmit.length === 0) {
        return true
      }
      return false
    }
    const submitPackageReturn = () => {
      if (isCanSubmit() === true) {
        if (checkingFile() === true) {
          emit('submitPackageReturns', listPackage.value)
        } else {
          $toast.add({
            severity: 'error',
            detail: 'Pastikan bukti foto sudah diisi',
            group: 'bc',
            life: 1500
          })
        }
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Pastikan alasan pengembalian sudah diisi',
          group: 'bc',
          life: 1500
        })
      }
    }

    return {
      isShowModal,
      hideDialog,
      listPackage,
      API_BASE,
      submitPackageReturn,
      isCanSubmit
    }
  }
})
